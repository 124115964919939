import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ApiService, I18nService, ThemeService } from '@toteimlib/angular-lib';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  imports: [
    RouterOutlet,
    TranslateModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(private translateService: TranslateService, private i18nService: I18nService, private apiService: ApiService, private themeService: ThemeService) {
    let userLang: string = this.i18nService.getLang();

    this.translateService.addLangs(environment.langs);
    this.translateService.setDefaultLang('fr');

    this.apiService.initialize(environment.api_url, environment.version, '', '/login');

    if (environment.langs.includes(userLang)) {
      this.translateService.use(userLang);
    }
    else {
      this.translateService.use('fr');
    }
  }

}
