import { definePreset } from '@primeng/themes';
import Lara from '@primeng/themes/lara';

export function toteimTheme(): any {
  return definePreset(Lara, {
    primitive: {
      borderRadius: {
        none: '0',
        xs: '4px',
        sm: '6px',
        md: '8px',
        lg: '10px',
        xl: '12px'
      },
      blue: {
        50: '#B6E2F6',
        100: '#A4DBF4',
        200: '#7FCCF0',
        300: '#5BBEEC',
        400: '#36AFE7',
        500: '#1895CF',
        600: '#1584B7',
        700: '#116992',
        800: '#116992',
        900: '#093549',
        950: '#041A25'
      },
      grey: {
        50: '#FFFFFF',
        100: '#F4F4F4',
        200: '#D0D0D0',
        300: '#ABABAB',
        400: '#868686',
        500: '#616161',
        600: '#5C5C5C',
        700: '#525252',
        800: '#474747',
        900: '#3D3D3D',
        950: '#333333'
      },
      green: {
        50: '#DBE6B3',
        100: '#D4E0A3',
        200: '#CDDB94',
        300: '#C6D685',
        400: '#BFD175',
        500: '#B5CB61',
        600: '#B0C757',
        700: '#A9C247',
        800: '#9FB83D',
        900: '#92A838',
        950: '#859933'
      },
      red: {
        50: '#D87D89',
        100: '#D4707D',
        200: '#D06270',
        300: '#CB5262',
        400: '#C74354',
        500: '#9F303E',
        600: '#842833',
        700: '#78242E',
        800: '#6D212A',
        900: '#631E26',
        950: '#5A1B23'
      },
      background: {
        100: '#F7F7F7',
        900: '#0C0A09'
      },
      midground: {
        100: '#FFFFFF',
        900: '#1C1917'
      },
      foreground: {
        100: '#484848',
        500: '#919191',
        900: '#FFFFFF'
      },
      muted: {
        100: '#F4F4F4',
        900: '#919191'
      },
      mutedForeground: {
        100: '#919191',
        900: '#F4F4F4'
      },
      success: {
        100: '#EEF3DD',
        900: '#545F2B'
      },
      successForeground: {
        100: '#545F2B',
        900: '#EEF3DD'
      },
      pending: {
        100: '#F4F4F4',
        900: '#919191'
      },
      pendingForeground: {
        100: '#919191',
        900: '#F4F4F4'
      },
      warning: {
        100: '#FFF3D4',
        900: '#75570D'
      },
      warningForeground: {
        100: '#75570D',
        900: '#FFF3D4'
      },
      danger: {
        100: '#FDEDEE',
        900: '#9F303E'
      },
      dangerForeground: {
        100: '#9F303E',
        900: '#FDEDEE'
      }
    },
    semantic: {
      transitionDuration: '0s',
      focusRing: {
        width: '2px',
        style: 'solid',
        color: '{primary.color}',
        offset: '2px',
        shadow: 'none'
      },
      iconSize: '1rem',
      anchorGutter: '0',
      primary: {
        50: '{blue.50}',
        100: '{blue.100}',
        200: '{blue.200}',
        300: '{blue.300}',
        400: '{blue.400}',
        500: '{blue.500}',
        600: '{blue.600}',
        700: '{blue.700}',
        800: '{blue.800}',
        900: '{blue.900}',
        950: '{blue.950}'
      },
      secondary: {
        50: '{grey.50}',
        100: '{grey.100}',
        200: '{grey.200}',
        300: '{grey.300}',
        400: '{grey.400}',
        500: '{grey.500}',
        600: '{grey.600}',
        700: '{grey.700}',
        800: '{grey.800}',
        900: '{grey.900}',
        950: '{grey.950}'
      },
      accent: {
        50: '{green.50}',
        100: '{green.100}',
        200: '{green.200}',
        300: '{green.300}',
        400: '{green.400}',
        500: '{green.500}',
        600: '{green.600}',
        700: '{green.700}',
        800: '{green.800}',
        900: '{green.900}',
        950: '{green.950}'
      },
      destructive: {
        50: '{red.50}',
        100: '{red.100}',
        200: '{red.200}',
        300: '{red.300}',
        400: '{red.400}',
        500: '{red.500}',
        600: '{red.600}',
        700: '{red.700}',
        800: '{red.800}',
        900: '{red.900}',
        950: '{red.950}'
      },
      formField: {
        paddingX: '0.75rem',
        paddingY: '0.5rem',
        borderRadius: '{border.radius.xs}',
        focusRing: {
          width: '2px',
          style: 'solid',
          color: '{primary.color}',
          offset: '-1px',
          shadow: 'none'
        },
        transitionDuration: '{transition.duration}'
      },
      list: {
        padding: '0.125rem 0',
        gap: '0',
        header: {
          padding: '0.5rem 0.75rem 0.375rem 0.75rem'
        },
        option: {
          padding: '0.5rem 0.75rem',
          borderRadius: '0'
        },
        optionGroup: {
          padding: '0.5rem 0.75rem',
          fontWeight: '700'
        }
      },
      content: {
        borderRadius: '{border.radius.xs}'
      },
      mask: {
        transitionDuration: '0.15s'
      },
      navigation: {
        list: {
          padding: '0.125rem 0',
          gap: '0'
        },
        item: {
          padding: '0.5rem 0.75rem',
          borderRadius: '0',
          gap: '0.5rem'
        },
        submenuLabel: {
          padding: '0.5rem 0.75rem',
          fontWeight: '700'
        },
        submenuIcon: {
          size: '0.875rem'
        }
      },
      overlay: {
        select: {
          borderRadius: '{border.radius.xs}',
          shadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'
        },
        popover: {
          borderRadius: '{border.radius.xs}',
          padding: '0.75rem',
          shadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'
        },
        modal: {
          borderRadius: '{border.radius.xs}',
          padding: '1.25rem',
          shadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)'
        },
        navigation: {
          shadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'
        }
      },
      colorScheme: {
        light: {
          surface: {
            0: '#ffffff',
            50: '{slate.50}',
            100: '{slate.100}',
            200: '{slate.200}',
            300: '{slate.300}',
            400: '{slate.400}',
            500: '{slate.500}',
            600: '{slate.600}',
            700: '{slate.700}',
            800: '{slate.800}',
            900: '{slate.900}',
            950: '{slate.950}'
          },
          primary: {
            color: '{primary.500}',
            contrastColor: '#ffffff',
            hoverColor: '{primary.700}',
            activeColor: '{primary.800}'
          },
          highlight: {
            background: '{primary.600}',
            focusBackground: '{primary.700}',
            color: '#ffffff',
            focusColor: '#ffffff'
          },
          mask: {
            background: 'rgba(0,0,0,0.4)',
            color: '{surface.200}'
          },
          formField: {
            background: '{surface.0}',
            disabledBackground: '{surface.300}',
            filledBackground: '{surface.100}',
            filledFocusBackground: '{surface.100}',
            borderColor: '{surface.500}',
            hoverBorderColor: '{surface.500}',
            focusBorderColor: '{surface.500}',
            invalidBorderColor: '{red.500}',
            color: '{surface.900}',
            disabledColor: '{surface.600}',
            placeholderColor: '{surface.600}',
            floatLabelColor: '{surface.600}',
            floatLabelFocusColor: '{surface.600}',
            floatLabelInvalidColor: '{red.500}',
            iconColor: '{surface.900}',
            shadow: 'none'
          },
          text: {
            color: '{surface.900}',
            hoverColor: '{surface.950}',
            mutedColor: '{surface.600}',
            hoverMutedColor: '{surface.700}'
          },
          content: {
            background: '{surface.0}',
            hoverBackground: '{surface.200}',
            borderColor: '{surface.400}',
            color: '{text.color}',
            hoverColor: '{text.hover.color}'
          },
          overlay: {
            select: {
              background: '{surface.0}',
              borderColor: 'transparent',
              color: '{text.color}'
            },
            popover: {
              background: '{surface.0}',
              borderColor: 'transparent',
              color: '{text.color}'
            },
            modal: {
              background: '{surface.0}',
              borderColor: 'transparent',
              color: '{text.color}'
            }
          },
          list: {
            option: {
              focusBackground: '{surface.200}',
              selectedBackground: '{highlight.background}',
              selectedFocusBackground: '{highlight.focus.background}',
              color: '{text.color}',
              focusColor: '{text.hover.color}',
              selectedColor: '{highlight.color}',
              selectedFocusColor: '{highlight.focus.color}',
              icon: {
                color: '{text.muted.color}',
                focusColor: '{text.hover.muted.color}'
              }
            },
            optionGroup: {
              background: 'transparent',
              color: '{text.color}'
            }
          },
          navigation: {
            item: {
              focusBackground: '{primary.color}',
              activeBackground: '{surface.200}',
              color: '{text.color}',
              focusColor: '{primary.contrast.color}',
              activeColor: '{text.hover.color}',
              icon: {
                color: '{text.muted.color}',
                focusColor: '{primary.contrast.color}',
                activeColor: '{text.hover.muted.color}'
              }
            },
            submenuLabel: {
              background: 'transparent',
              color: '{text.color}'
            },
            submenuIcon: {
              color: '{text.muted.color}',
              focusColor: '{primary.contrast.color}',
              activeColor: '{text.hover.muted.color}'
            }
          }
        },
        dark: {
          surface: {
            0: '#ffffff',
            50: '{zinc.50}',
            100: '{zinc.100}',
            200: '{zinc.200}',
            300: '{zinc.300}',
            400: '{zinc.400}',
            500: '{zinc.500}',
            600: '{zinc.600}',
            700: '{zinc.700}',
            800: '{zinc.800}',
            900: '{zinc.900}',
            950: '{zinc.950}'
          },
          primary: {
            color: '{primary.500}',
            contrastColor: '{surface.950}',
            hoverColor: '{primary.400}',
            activeColor: '{primary.300}'
          },
          highlight: {
            background: '{primary.500}',
            focusBackground: '{primary.400}',
            color: '{surface.950}',
            focusColor: '{surface.950}'
          },
          mask: {
            background: 'rgba(0,0,0,0.6)',
            color: '{surface.200}'
          },
          formField: {
            background: '{surface.950}',
            disabledBackground: '{surface.700}',
            filledBackground: '{surface.800}',
            filledFocusBackground: '{surface.800}',
            borderColor: '{surface.500}',
            hoverBorderColor: '{surface.500}',
            focusBorderColor: '{surface.500}',
            invalidBorderColor: '{red.400}',
            color: '{surface.0}',
            disabledColor: '{surface.400}',
            placeholderColor: '{surface.400}',
            floatLabelColor: '{surface.400}',
            floatLabelFocusColor: '{surface.400}',
            floatLabelInvalidColor: '{red.400}',
            iconColor: '{surface.0}',
            shadow: 'none'
          },
          text: {
            color: '{surface.0}',
            hoverColor: '{surface.0}',
            mutedColor: '{surface.400}',
            hoverMutedColor: '{surface.300}'
          },
          content: {
            background: '{surface.900}',
            hoverBackground: '{surface.700}',
            borderColor: '{surface.500}',
            color: '{text.color}',
            hoverColor: '{text.hover.color}'
          },
          overlay: {
            select: {
              background: '{surface.900}',
              borderColor: '{surface.700}',
              color: '{text.color}'
            },
            popover: {
              background: '{surface.900}',
              borderColor: '{surface.700}',
              color: '{text.color}'
            },
            modal: {
              background: '{surface.900}',
              borderColor: '{surface.700}',
              color: '{text.color}'
            }
          },
          list: {
            option: {
              focusBackground: '{surface.700}',
              selectedBackground: '{highlight.background}',
              selectedFocusBackground: '{highlight.focus.background}',
              color: '{text.color}',
              focusColor: '{text.hover.color}',
              selectedColor: '{highlight.color}',
              selectedFocusColor: '{highlight.focus.color}',
              icon: {
                color: '{text.muted.color}',
                focusColor: '{text.hover.muted.color}'
              }
            },
            optionGroup: {
              background: 'transparent',
              color: '{text.color}'
            }
          },
          navigation: {
            item: {
              focusBackground: '{primary.color}',
              activeBackground: '{surface.700}',
              color: '{text.color}',
              focusColor: '{primary.contrast.color}',
              activeColor: '{text.color}',
              icon: {
                color: '{text.muted.color}',
                focusColor: '{primary.contrast.color}',
                activeColor: '{text.hover.muted.color}'
              }
            },
            submenuLabel: {
              background: 'transparent',
              color: '{text.color}'
            },
            submenuIcon: {
              color: '{text.muted.color}',
              focusColor: '{primary.contrast.color}',
              activeColor: '{text.hover.muted.color}'
            }
          }
        }
      }
    },
    components: {
      inputtext: {
        border: {
          radius: '{border.radius.lg}',
          color: '{foreground.500}'
        },
        focus: {
          ring: {
            color: '{foreground.500}'
          }
        }
      },
      button: {
        border: {
          radius: '{border.radius.lg}'
        }
      },
      card: {
        border: {
          radius: '{border.radius.lg}'
        }
      },
      toast: {
        colorScheme: {
          light: {
            error: {
              background: '{danger.100}',
              color: '{dangerForeground.100}',
              border: {
                color: '{dangerForeground.100}'
              },
              close: {
                button: {
                  hover: {
                    background: 'none'
                  }
                }
              }
            }
          },
          dark: {
            error: {
              background: '{danger.900}',
              color: '{dangerForeground.900}',
              border: {
                color: '{dangerForeground.900}'
              },
              close: {
                button: {
                  hover: {
                    background: 'none'
                  }
                }
              }
            }
          }
        }
      },
      progressspinner: {
        colorScheme: {
          light: {
            color: {
              1: '{accent.100}',
              2: '{accent.400}',
              3: '{accent.700}',
              4: '{accent.900}'
            }
          },
          dark: {
            color: {
              1: '{accent.100}',
              2: '{accent.400}',
              3: '{accent.700}',
              4: '{accent.900}'
            }
          }
        }
      },
      message: {
        colorScheme: {
          light: {
            secondary: {
              background: '{primary.50}'
            }
          },
          dark: {
            secondary: {
              background: '{primary.950}'
            }
          }
        }
      },
      accordion: {
        colorScheme: {
          light: {
            panel: {
              border: {
                width: 0
              }
            },
            header: {
              background: '{primary.50}',
              first: {
                border: {
                  width: 0
                }
              },
              hover: {
                background: '{primary.50}',
              },
              border: {
                width: 0
              },
              active: {
                hover: {
                  background: '{primary.50}',
                },
                background: '{primary.50}'
              }
            },
            content: {
              background: '{primary.50}',
              border: {
                width: 0
              }
            }
          },
          dark: {
            panel: {
              border: {
                width: 0
              }
            },
            header: {
              background: '{primary.950}',
              first: {
                border: {
                  width: 0
                }
              },
              hover: {
                background: '{primary.950}',
              },
              border: {
                width: 0,
              },
              active: {
                hover: {
                  background: '{primary.950}',
                },
                background: '{primary.950}'
              }
            },
            content: {
              background: '{primary.950}',
              border: {
                width: 0
              }
            }
          }
        }
      }
    }
  });
}
