export const environment = {
  production: false,
  url: 'https://dev.v2.toteim.com',
  api_url: 'https://apidev.v2.toteim.com/occupant',
  langs: [
    'en',
    'fr'
  ],
  version: 'f208fc424bb4fbb22cdecdca1f6159899df56ba0'
};
